import {
  ModalSkeleton,
  queryClient,
  useStore,
} from '@buildable-microservices/components';
import { ChakraProvider } from '@chakra-ui/react';
import * as FullStory from '@fullstory/browser';
import 'inter-ui/inter.css';
import { AppProps } from 'next/app';
import React, { useEffect } from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Hydrate } from 'react-query/hydration';
import BuilderContextProvider from '../context/BuilderContext';
import theme from '../styles/theme';
import './styles.css';

const App = ({ Component, pageProps }: AppProps) => {
  useStore();

  useEffect(() => {
    FullStory.init({ orgId: 'ZZ4H5', namespace: 'FS' });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ChakraProvider theme={theme}>
          <BuilderContextProvider>
            <ModalSkeleton />
            <Component {...pageProps} />
          </BuilderContextProvider>
        </ChakraProvider>
      </Hydrate>
    </QueryClientProvider>
  );
};

export default App;
